<template>
    <div class="switch-btn">
        <button v-bind="$attrs" v-for="btn in switches" :key="btn.value" @click="onClick(btn.value)" :class="[{'active' : active===btn.value}]" :style="active===btn.value && activeColor ? {backgroundColor: activeColor} : {}">
            <p class="btn-body" :style="active===btn.value && activeColor ? {color: '#1A1B20'} : {}">
                <i v-if="btn.icon" v-html="btn.icon" />
                <span v-html="btn.title" />
            </p>
        </button>
    </div>
</template>

<script>
    // @switchers = Array of Objects type {
    //     title: String,
    //     icon: String,
    //     value: String
    // }
    export default {
        name: "TSwitchBtn",
        props: {
            switches: {
                type: Array
            },
            active: [String, Number, null],
            activeColor: {
                type: String,
                default: null
            }
        },
        methods: {
            onClick(newActive) {
                this.$emit("click", newActive)
            }
        }
    }
</script>

<style scoped lang="scss">
    .switch-btn {
        border: 1px solid $grayscale-light;
        box-sizing: content-box;
        border-radius: 11px;
        overflow: hidden;
        max-width: max-content;
        button {
            cursor: pointer;
            height: 44px;
            min-width: 180px;
            transition: background-color 300ms;


            appearance: auto;
            -webkit-writing-mode: horizontal-tb !important;
            text-rendering: auto;
            color: inherit;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            text-indent: 0px;
            text-shadow: none;
            display: inline-block;
            text-align: center;
            align-items: flex-start;
            background-color: inherit;
            box-sizing: border-box;
            margin: 0em;
            font: inherit;
            padding: 1px 6px;
            border: none;
            border-right: 1px solid $grayscale-middle;
            color: $white;

            &:hover {
                background-color: $grayscale-middle-light;
            }
            &.active {
                background-color: $blue-middle;
                color: $grayscale-black;
            }

            & .btn-body {
                margin: 0;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                & i {
                    padding-right: 12px;
                    line-height: 0;
                }
                & span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: currentColor;

                    letter-spacing: 0.0125em;
                    text-transform: uppercase;
                }
            }
        }
    }
</style>