<template>
  <div class="spot">
    <div class="switch-wrapper">
      <t-switch-btn
          :switches="exchangeList"
          :active="_activeExchange" @click="switcher" active-color="#FFAB2D"/>
    </div>
    <div class="switch-wrapper" v-if="accountList && accountList.length > 1">
      <t-switch-btn
              :switches="accountList"
              :active="_activeAccount" @click="switcher($event, 'account')" active-color="#FFAB2D"/>
    </div>
    <spot-direvatives-table v-if="!isNoData" :spot="exchangeSpot" @delete-row="$emit('delete-row', $event)" />
    <div v-else class="noData">
      <p>Извините, данные обновляются. Время обновления составляет примерно 3 минуты</p>
    </div>
  </div>
</template>

<script>

import TSwitchBtn from "@/components/Blocks/TSwitchBtn";
import SpotDirevativesTable from "@/components/Blocks/SpotDirevativesTable";
export default {
  name: "SpotPage",
  components: {SpotDirevativesTable, TSwitchBtn},
  props: {
    exchangeList: Array,
    accountList: Array,
    activeExchange: [Object, null],
    activeAccount: [Object, null],
    exchangeSpot: Object
  },
  computed: {
    _activeExchange() {
      return this.activeExchange?.value || ""
    },
    _activeAccount() {
      return this.activeAccount?.value || ""
    },
    exchangeAccount() {
      return this.exchangeSpot || null
    },
    isNoData() {
      return this.exchangeSpot?.is_refreshing || false
    }
  },
  methods: {
    switcher(value, account) {
      account
              ? this.$emit('switcher-account', this.accountList.find(el => el.value === value))
              : this.$emit('switcher', this.exchangeList.find(el => el.value === value))
    }
  }
}
</script>

<style lang="scss">
.spot {
  margin-top: 105px;
  display: flex;
  row-gap: 27px;
  width: 100%;
  flex-direction: column;
  & .noData {
    display: flex;
  }
}
.switch-wrapper {
  display: flex;
  padding: 0 0 12px;
  background-color: $grayscale-dark;
}
</style>