<template>
    <div class="spot-direvatives" v-if="_spot">
        <div class="t-wrapper">
            <table>
                <tbody>
                <tr>
                    <td class="vertical-header grey-background yellow">Биржа</td>
                    <td :colspan="$route.path.includes('spot') ? 7 : 8" class="-align-center spot-header"> {{_spot.name}}</td>
                </tr>
                <tr v-if="balance">
                    <td class="vertical-header grey-background yellow">Баланс | Доступно</td>
                    <td>$ {{ balance.usd.toLocaleString() }}</td>
                    <td v-if="$route.path.includes('direvatives')">$ {{balance.available_balance.toLocaleString()}}</td>
                    <td class="vertical-header grey-background yellow">Исх. депозит | Изм. %</td>
                    <td>$ {{ balance.initial_balance.toLocaleString() }}</td>
                    <td :class="[{'red' : balance.initial_balance_change_percent < 0}, {'green' : balance.initial_balance_change_percent > 0}]">{{ balance.initial_balance_change_percent.toLocaleString() }}%</td>
                    <td v-if="$route.path.includes('spot')">
                        $ {{balance.usdt.toLocaleString() }}
                    </td>
                    <td v-if="$route.path.includes('spot')" class="vertical-header grey-background yellow">
                        Текущий результат
                    </td>
                    <td v-if="$route.path.includes('spot')" :class="[{'red' : balance.change_percent < 0}, {'green' : balance.change_percent > 0}]">{{
                        balance.change_percent.toLocaleString() }} %
                    </td>
                    <td v-if="$route.path.includes('spot')" :class="[{'red' : balance.change_usd < 0}, {'green' : balance.change_usd > 0}]">
                        {{
                        balance.change_usd.toString().includes('-')
                        ? `- $${balance.change_usd.toLocaleString().slice(1)}`
                        : `$${balance.change_usd.toLocaleString()}`
                        }}
                    </td>
                    <td v-if="$route.path.includes('spot')" :class="[{'red' : balance.change_btc < 0}, {'green' : balance.change_btc > 0}]">
                        {{
                        balance.change_btc.toString().includes('-')
                        ? `- ${balance.change_btc.toLocaleString().slice(1)}`
                        : `${balance.change_btc.toLocaleString()}`
                        }}
                        <span class="yellow">BTC</span>
                    </td>
                    <td v-if="$route.path.includes('direvatives')" class="grey-background yellow">PNL по всем позициям</td>
                    <td v-if="$route.path.includes('direvatives')" :class="[{'red' : balance.change_percent < 0}, {'green' : balance.change_percent > 0}]">{{
                        balance.change_percent.toLocaleString() }} %
                    </td>
                    <td v-if="$route.path.includes('direvatives')" :class="[{'red' : balance.change_usd < 0}, {'green' : balance.change_usd > 0}]">
                        {{
                        balance.change_usd.toString().includes('-')
                        ? `-$ ${balance.change_usd.toLocaleString().slice(1)}`
                        : `$ ${balance.change_usd.toLocaleString()}`
                        }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="t-wrapper scrollable" v-if="_coins && _coins.length > 0">
            <table v-if="$route.path.includes('spot')">
                <thead class="stick-head">
                <tr>
                    <th class="grey-background yellow">Монета</th>
                    <th class="grey-background yellow">Кол-во</th>
                    <th class="grey-background yellow">USD экв.(исходный)</th>
                    <th class="grey-background yellow">USD экв.(текущий)</th>
                    <th class="grey-background yellow">Изм. за 24Ч</th>
                    <th class="grey-background yellow">Цена покупки</th>
                    <th class="grey-background yellow">Текущий курс</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="coin in _coins" :key="coin.name">
                    <td class="coin-header">{{coin.name}}</td>
                    <td>{{coin.ammount.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:12})}}
                    </td>
                    <td>$ {{coin.start_ammount_usd.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:2})}}
                    </td>
                    <td>$ {{coin.ammount_usd.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:2})}}
                    </td>
                    <td :class="[{'red' : coin.change_day_before < 0}, {'green' : coin.change_day_before > 0}]">
                        {{coin.change_day_before > 0 ? '+' : ''}}{{coin.change_day_before.toLocaleString()}}%
                    </td>
                    <td>{{coin.start_price.toLocaleString()}}</td>
                    <td>{{coin.exchange_rate.toLocaleString()}}</td>
                </tr>
                </tbody>
            </table>
            <table v-else>
                <thead class="stick-head">
                <tr>
                    <th class="grey-background yellow">Позиция</th>
                    <th class="grey-background yellow">Пара</th>
                    <th class="grey-background yellow">Монет</th>
                    <th class="grey-background yellow">Сумма</th>
                    <th class="grey-background yellow">Плечо</th>
                    <th class="grey-background yellow">PNL %</th>
                    <th class="grey-background yellow">PNL $</th>
                    <th class="grey-background yellow">Цена входа</th>
                    <th class="grey-background yellow">Текущий курс</th>
                    <th class="grey-background yellow">Ликвидация</th>
                    <th class="grey-background yellow">Обеспечение</th>
                    <th class="lightred-bg white">Закрыть</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="coin in _coins" :key="coin.name+coin.side+coin.current_price">
                    <td style="color: white" :class="[{'lightgreen-bg' : coin.side.match('Buy')}, {'lightred-bg' : coin.side.match('Sell')}]">
                      {{coin.side.match('Buy') ? 'LONG' : 'SHORT'}}
                    </td>
                    <td class="water-bg white">{{coin.name}}</td>
                    <td>{{coin.ammount.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:12})}}</td>
                    <td class="bold">$ {{coin.ammount_usd.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:12})}}</td>
                    <td style="color: #0071c1; text-align: center">x {{coin.leverage}}</td>
                    <td :class="[
                        {'red' : coin.pnl_percent < 0},
                        {'green': coin.pnl_percent > 0}
                        ]"
                    >{{coin.pnl_percent > 0
                        ? `+${coin.pnl_percent.toLocaleString()}`
                        : coin.pnl_percent.toLocaleString()}}%
                    </td>
                    <td
                        :class="[
                            {'red' : coin.pnl_usd < 0},
                            {'green': coin.pnl_usd > 0}
                        ]"
                    >
                        {{coin.pnl_usd.toString().includes('-')
                            ? `- $ ${coin.pnl_usd.toLocaleString().slice(1)}`
                            : `$ ${coin.pnl_usd.toLocaleString()}`
                        }}</td>
                    <td>$ {{coin.open_price.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:2})}}</td>
                    <td>$ {{coin.current_price.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:2})}}</td>
                    <td>$ {{coin.liq_price.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:2})}}</td>
                    <td>$ {{coin.margin_provision.toLocaleString("ru-RU",{useGrouping:true, minimumFractionDigits:0,
                        maximumFractionDigits:2})}}</td>
                    <td class="yellow">
                        <div class="close-in-table-btn" @click="$emit('delete-row', coin.id)">
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.71891 0L5.12891 3.59L1.53891 0L0.128906 1.41L3.71891 5L0.128906 8.59L1.53891 10L5.12891 6.41L8.71891 10L10.1289 8.59L6.53891 5L10.1289 1.41L8.71891 0Z" fill="#FFAB2D"/>
                            </svg>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SpotDirevativesTable",
        props: ['spot'],
        computed: {
            _spot() {
                return this.spot || null
            },
            _coins() {
                return this.spot?.coins || null
            },
            balance() {
                return this._spot?.balance || null
            }
        }
    }
</script>

<style lang="scss">
    .spot-direvatives {
        display: flex;
        width: fit-content;
        row-gap: 27px;
        flex-direction: column;

        & .t-wrapper {
            width: 100%;
            border: 2px solid #5B5C67;
            border-radius: 15px;
            overflow: hidden;

            & table {
                width: 100%;
                border-collapse: collapse;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;

                & td, th {
                    padding: 5px 10px;
                    border-bottom: 2px solid #5B5C67;
                    border-right: 2px solid #5B5C67;

                    &:last-child {
                        border-right: 0;
                    }
                }

                & th {
                    padding: 10px;
                }

                & tr {
                    &:last-child {
                        & td {
                            border-bottom: 0;
                        }
                    }
                }

                & tbody {
                  & tr {
                    &:nth-child(even) {
                      background-color: #222329;
                    }

                    &:hover {
                      background-color: #41424b;
                    }
                  }
                }

                & .vertical-header {
                    font-weight: 500;
                    letter-spacing: 0.0125em;
                    width: 200px;
                    padding: 10px;
                }
            }
        }
    }

    .-align-center {
        text-align: center;
    }
    .spot-header {
      background-color: #0071c1 !important;
      text-transform: uppercase;
    }
  .grey-background {
    background-color: #595959 !important;
    border-color: #222329 !important;
  }
  .coin-header {
    background-color: #17375e !important;
  }
    .scrollable {
        height: 100%;
        max-height: calc(100vh - 370px);
        overflow: auto !important;
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .close-in-table-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
</style>
