<template>
    <spot-page
            :exchange-list="EXCHANGE_MARGIN_LIST"
            :account-list="EXCHANGE_MARGIN_ACCOUNTS"
            :active-exchange="_exchange"
            :active-account="_account"
            :exchange-spot="EXCHANGE_MARGIN"
            @switcher="onSwitch"
            @switcher-account="onSwitchAccount"
            @delete-row="deleteRow"
    />
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import SpotPage from "@/components/Pages/SpotPage";

    export default {
        name: "Direvatives",
        components: {SpotPage},
        computed: {
            ...mapGetters(['EXCHANGE_MARGIN_LIST', 'EXCHANGE_MARGIN_ACCOUNTS', 'EXCHANGE_MARGIN']),
            _exchange: {
                get() {
                    return this.exchange || this.EXCHANGE_MARGIN_LIST[0]
                },
                set(val) {
                    this.exchange = val
                }
            },
            _account: {
                get() {
                    return this.account || this.EXCHANGE_MARGIN_ACCOUNTS[0]
                },
                set(val) {
                    this.account = val
                }
            },
        },
        data: () => ({
            exchange: null,
            account: null,
        }),
        methods: {
            ...mapActions(['getEXCHANGE_LIST', 'getEXCHANGE_ACCOUNTS', 'getMARGIN_DATA', 'SET_LOADER', "setREFRESH", "clearREFRESH", "delEXCHANGE_POSITION"]),
            async onSwitch(val) {
                this.SET_LOADER(true)
                this.exchange = val
                await this.getEXCHANGE_ACCOUNTS(this._exchange.value)
                this._account = this.EXCHANGE_MARGIN_ACCOUNTS[0]
                await this.getMARGIN_DATA(`${this._exchange.value}/${this._account['account_id']}`)
                this.SET_LOADER(this.EXCHANGE_MARGIN.is_refreshing)
            },
            async onSwitchAccount(val) {
                this.SET_LOADER(true)
                this.account = val
                await this.getMARGIN_DATA(`${this._exchange.value}/${this._account['account_id']}`)
                this.SET_LOADER(this.EXCHANGE_MARGIN.is_refreshing)
            },
            async refreshSignal() {
                this.SET_LOADER(true)
                await this.getEXCHANGE_LIST()
                await this.getEXCHANGE_ACCOUNTS(this._exchange.value)
                await this.getMARGIN_DATA(`${this._exchange.value}/${this._account['account_id']}`)
                this.setREFRESH(this.refreshSignal)
                this.SET_LOADER(this.EXCHANGE_MARGIN.is_refreshing)
            },
            async deleteRow(rowId) {
                const result = await this.delEXCHANGE_POSITION(`${this._account['account_id']}/${rowId}`)
                if (result.results.Success) {
                    await this.clearREFRESH()
                    await this.refreshSignal()
                }
            }
        },
        async mounted() {
            await this.refreshSignal()
        },
        destroyed() {
            this.clearREFRESH()
        },
    }
</script>